import React from 'react';
import ReactDOM from 'react-dom';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import Home from './Home';
import Privacy from './Privacy';
import App from './App';

import './index.css';

const routerItems = createBrowserRouter([
  {
      path: "/",
      element: <Home />,
  },
  {
      path: "/privacy",
      element: <Privacy />,
    },
  {
      path: "/webapp",
      element: <App />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={routerItems} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

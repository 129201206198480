import React, { useCallback, useState, useRef, useMemo } from "react";
import Head from "./Head";
import { Link } from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import trophy from './winner.png'
import './App.css';

const App = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [qPlayersOpen, setQPlayersOpen] = useState(false);
  const [qDealerOpen, setQDealerOpen] = useState(false);
  const [player, setPlayer] = useState('');
  const [players, setPlayers] = useState([]);
  const playerInput = useRef();
  const scoreInputs = useRef([]);

  const [startingDealer, setStartingDealer] = useState(0);
  const [currentDealer, setCurrentDealer] = useState(false);
  const [currentWildcard, setCurrentWildcard] = useState(false);
  const [scores, setScores] = useState(false);
  const [roundScores, setRoundScores] = useState([]);
  const [sessionResults, setSessionResults] = useState({});
  const [gameFinished, setGameFinished] = useState(false);

  const [errorMsg, setErrorMsg] = useState(false);

  const storageKey = "__a2kD";

  const cards = [
    'A',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    'J',
    'Q',
    'K',
  ];

  const createPlayer = (e) => {
    e && e.preventDefault();
    const currentPlayers = [...players];
    currentPlayers.push(player);
    setPlayers(currentPlayers);
    setPlayer('');
    playerInput.current.value = '';
  }

  const removePlayer = (index) => {
    const currentPlayers = [...players];
    currentPlayers.splice(index, 1);
    setPlayers(currentPlayers);
  }

  const getNextPlayer = () => {
    const nextIndex = currentDealer + 1;
    const nextPlayer = players.length > nextIndex ? nextIndex : 0;
    return players[nextPlayer];
  }

  const getNextPlayerIndex = () => {
    const nextIndex = currentDealer + 1;
    const nextPlayer = players.length > nextIndex ? nextIndex : 0;
    return nextPlayer;
  }

  const storeLocalData = (_players, _newGameScores, _nextWildcard, _startingDealer, _currentDealer, _sessionResults) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(storageKey, JSON.stringify({
        players: _players,
        newGameScores: _newGameScores, 
        nextWildcard: _nextWildcard, 
        startingDealer: _startingDealer, 
        currentDealer: _currentDealer,
        sessionResults: _sessionResults
      }))
    }
  }

  const loadLocalData = useCallback(() => {
    if (typeof window !== 'undefined') {
      const data = window.localStorage.getItem(storageKey);
      try {
        if (data) {
          const object = JSON.parse(data);
          setPlayers(object.players);
          setScores(object.newGameScores);
          setCurrentWildcard(object.nextWildcard);
          setStartingDealer(object.startingDealer);
          setCurrentDealer(object.currentDealer);
          setSessionResults(object.sessionResults);
        }
      } catch {
        clearLocalData();
      }
    }
  }, []);

  const clearLocalData = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(storageKey);
    }
  }

  useMemo(() => loadLocalData(), [loadLocalData]);

  const newGame = (clearPlayers, rotateDealer) => {
    setDialogOpen(false);
    setQPlayersOpen(false);
    setQDealerOpen(false);
    if (players.length > 0 && clearPlayers === null) {
      // Players set, see if they need to be retained
      setQPlayersOpen(true);
      return false;
    } else if (clearPlayers === false && rotateDealer === null) {
      // Players being retained, see if we need to roll dealer
      setQDealerOpen(true);
      return false;
    }

    if (clearPlayers === true) {
      setStartingDealer(0);
      setCurrentDealer(false);
      setPlayers([]);
      setSessionResults([]);
      clearLocalData();
    }

    let nextDealer = startingDealer;
    if (rotateDealer === true) {
      const nextIndex = startingDealer + 1;
      nextDealer = players.length > nextIndex ? nextIndex : 0;
      setStartingDealer(nextDealer);
    }

    setCurrentWildcard(false);
    setScores(false);
    setRoundScores([]);
    setGameFinished(false);
    
    if (clearPlayers === false) {
      startGame(nextDealer);
    }
  }

  const startGame = (dealer) => {
    setCurrentDealer(dealer);
    setCurrentWildcard(0);
    const scores = players.map((player, playerIndex) => {
      return {player: playerIndex, score: 0};
    });
    setScores(scores);
  }

  const storeRoundScores = (e, playerIndex) => {
    const currentRoundScores = [...roundScores];
    const playerRoundScore = parseInt(e.target.value);
    const newRoundScore = currentRoundScores.filter(roundScore => {
      return roundScore.player === playerIndex ? false : roundScore;
    });

    newRoundScore.push({player: playerIndex, score: playerRoundScore});

    setRoundScores([...newRoundScore]);
  }

  const sortedScores = (_scores) => {
    const sortedScores = _scores.map((score, scoreIndex) => {
      const thePlayer = players[score.player];
      const theScore = score.score;
      return [thePlayer, theScore, score.player];
    });

    sortedScores.sort((a, b) => {
      return a[1] - b[1];
    });

    return sortedScores;
  }

  const submitScores = () => {
    if (roundScores.length !== players.length) {
      setErrorMsg("Some scores are missing - ensure the winner value is entered as zero.")
      return false;
    }

    if (roundScores.filter(roundScore => roundScore.score === 0).length === 0) {
      setErrorMsg("No winner was defined as everyone has a score. One person must be on zero.")
      return false;
    }

    const currentGameScores = [...scores];
    const newGameScores = currentGameScores.map(playerGameScore => {
      const playerRoundScore = roundScores.filter(roundScore => roundScore.player === playerGameScore.player);
      const newScore = playerGameScore.score + playerRoundScore[0].score;
      return {player: playerGameScore.player, score: newScore};
    });

    const nextWildcard = currentWildcard + 1;

    if (cards.length > nextWildcard) {
      // Next card as wild
      setScores(newGameScores);
      const dealer = getNextPlayerIndex();
      setCurrentDealer(dealer);
      setCurrentWildcard(nextWildcard);
      setRoundScores([]);
      setErrorMsg(false);
      storeLocalData(players, newGameScores, nextWildcard, startingDealer, dealer, sessionResults);
      scoreInputs.current.map(scoreInput => {
        if (scoreInput) {
          scoreInput.value = '';
        }
        return true;
      })
    } else {
      // Game is complete - display results
      setScores(newGameScores);
      setGameFinished(true);

      const _sortedScores = sortedScores(newGameScores);
      const session = sessionResults;
      if (Object.keys(session).length === 0) {
        // First game, setup session
        players.map((p, pI) => {session[pI] = 0; return true;});
      }

      session[_sortedScores[0][2]] = session[_sortedScores[0][2]] + 1;
      setSessionResults(session);
      storeLocalData(players, newGameScores, nextWildcard, startingDealer, currentDealer, session);
    }
  }

  const ouputScores = () => {
    const _sortedScores = sortedScores(scores);

    return _sortedScores.map((score, scoreIndex) => {
      const thePlayer = score[0];
      const theScore = score[1];
      const playerId = score[2];

      return (
        <li key={scoreIndex}>
          <span className="player-name">{thePlayer} ({playerId in sessionResults ? sessionResults[playerId] : 0})</span>
          <span className="player-score">{theScore}</span>
        </li>
      )
    });
  }

  return (
    <div className="App">
      <Head title="Ace to King" />

      <header className="App-header">
        <span>&nbsp;</span>
        <h1 className="logo"><Link to="/">A<span>to</span>K</Link></h1>
        <span role="presentation" className="App-link" onClick={() => setDialogOpen(true)}>New</span>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          open={dialogOpen}
        >
          <DialogContent>
            <strong>Are you sure you want to start a new game?</strong>
            <br /><br />
            This will clear any current game data.
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setDialogOpen(false)} color="primary">
              No
            </Button>
            <Button onClick={() => newGame(null, null)} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          open={qPlayersOpen}
        >
          <DialogContent>
            <strong>Use the same players from the previous game?</strong>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => newGame(true, null)} color="primary">
              No
            </Button>
            <Button onClick={() => newGame(false, null)} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          open={qDealerOpen}
        >
          <DialogContent>
            <strong>Would you like to rotate the starting dealer?</strong>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => newGame(false, false)} color="primary">
              No
            </Button>
            <Button onClick={() => newGame(false, true)} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </header>

      <main className="App-body">
        {(currentDealer === false && currentWildcard === false && scores === false && gameFinished === false) && (
          <div className="App-wrap center">
            <p>Enter player names in dealer order:</p>
            <ul className="player-list">
              {players && players.map((player, playerIndex) => (
                <li key={playerIndex}>{player} <Icon onClick={() => removePlayer(playerIndex)} fontSize="small" style={{ color: '#f17070' }}>remove_circle</Icon></li>
              ))}
              <li className="insert">
                <form onSubmit={(e) => createPlayer(e)}>
                  <input type="text" ref={playerInput} onChange={(e) => setPlayer(e.target.value)} />
                  <Icon className="addHandle" onClick={() => createPlayer()} fontSize="small" style={{ color: '#4caf50' }}>add_circle</Icon>
                </form>
              </li>
            </ul>
            {players.length > 1 && (
              <Button onClick={() => startGame(startingDealer)} variant="contained" color="primary">Start Game</Button>
            )}
          </div>
        )}

        {(currentDealer !== false && currentWildcard !== false && scores !== false && gameFinished === false) && (
          <>
            <div className="columns c2 border">
              <div className="App-wrap center">
                <h6>Current Wildcard</h6>
                <span className={`currentWildcard color${currentWildcard % 2 ? 'Red' : 'Black'}`} data-card={cards[currentWildcard]}><span>{cards[currentWildcard]}</span></span>
              </div>
              <div className="App-wrap center">
                <h6>Round Dealer</h6>
                <span className="currentDealer">{players[currentDealer]}</span>
                <h6>To Start</h6>
                <span className="currentDealer">{getNextPlayer()}</span>
              </div>
            </div>
            <div className="App-wrap center">
              <h5>End of Round Score</h5>
              <ul className="player-list">
                {players && players.map((player, playerIndex) => (
                    <li key={playerIndex} className="space">{player} <input type="number" pattern="\d*" ref={ref => scoreInputs.current.push(ref)} className="scoreInput" onChange={(e) => storeRoundScores(e, playerIndex)} /></li>
                ))}
              </ul>
              {errorMsg && (
                <p className="errorMsg">{errorMsg}</p>
              )}
              <Button onClick={() => submitScores()} variant="contained" color="primary">Submit round scores</Button>
            </div>
          </>
        )}

        {gameFinished !== false && (
          <div className="App-wrap center">
            <h5>Game FINISHED! And the winner is...</h5>
            <img src={trophy} alt="Trophy" width="200" />
            <ul className="final-results">
              {ouputScores()}
            </ul>
          </div>
        )}
      </main>

      {scores !== false && gameFinished === false && (
        <footer className="App-footer">
          <ul>
            {ouputScores()}
          </ul>
        </footer>
      )}
    </div>
  );
}

export default App;

import React from 'react';
import Head from './Head';
import Header from './components/Header';

const Privacy = () => {
    return (
        <div className="Privacy">
            <Head />
            <Header />

            <div className='contain-longform'>
                <h1 className='plain'>Privacy Policy for AtoK Card Game Scoring App</h1>

                <p>Last Updated: August 19, 2023</p>

                <p>We at ARD Web Development ("we," "us," or "our") value and respect your privacy. This Privacy Policy outlines
                    how we collect, use, disclose, and safeguard your personal information when you use our AtoK Card Game Scoring App
                    (referred to as "AtoK" or the "App") available on the Apple App Store. By downloading and using the App, you
                    agree to the practices described in this Privacy Policy.</p>

                <h2>Information We Collect</h2>

                <p>We do not collect any personal information from users of the AtoK App. The App is designed to provide a simple
                    and convenient card game scoring experience without requiring or storing any personal data. We do not ask for,
                    collect, or store any of the following information:</p>

                <ul>
                    <li>Personal identifiers such as names, addresses, or contact details.</li>
                    <li>Financial information, credit card numbers, or payment information.</li>
                    <li>Geolocation information or device location.</li>
                    <li>Sensitive information like health, race, religion, or other protected characteristics.</li>
                </ul>

                <h2>Automatically Collected Information</h2>

                <p>The AtoK App may collect certain information automatically, including:</p>

                <ul>
                    <li>Device Information: We may collect information about the device you use to access the App, including device
                        type, operating system version, and unique device identifiers.</li>
                    <li>Usage Information: We may collect information about your usage of the App, such as the features you interact
                        with and the actions you take within the App.</li>
                </ul>

                <h2>Data Usage and Sharing</h2>

                <p>We do not share, sell, or disclose any personal or automatically collected information to third parties. The
                    information collected by the App is used solely for the purpose of providing you with the card game scoring
                    functionality and enhancing your user experience.</p>

                <h2>Data Security</h2>

                <p>We prioritize the security of your information and have implemented reasonable measures to safeguard it. However,
                    please note that no method of transmission or electronic storage is 100% secure, and we cannot guarantee absolute
                    security.</p>

                <h2>Children's Privacy</h2>

                <p>The AtoK App is not intended for use by individuals under the age of 13. We do not knowingly collect personal
                    information from children under 13. If you are a parent or guardian and believe that your child has provided us
                    with personal information, please contact us at <a href="mailto:app-support@ardwebdevelopment.com?subject=AtoK+app">app-support@ardwebdevelopment.com</a>, and we will
                    promptly delete such information from our records.</p>

                <h2>Changes to This Privacy Policy</h2>

                <p>We may update this Privacy Policy from time to time to reflect changes to our practices or for other operational,
                    legal, or regulatory reasons. The updated policy will be posted on this page, and the "Last Updated" date at the
                    top will indicate when the changes took effect.</p>

                <h2>Contact Us</h2>

                <p>If you have any questions, concerns, or feedback about this Privacy Policy or our privacy practices, you can
                    contact us at <a href="mailto:app-support@ardwebdevelopment.com?subject=AtoK+app">app-support@ardwebdevelopment.com</a>.</p>

                <p>Thank you for choosing AtoK for your card game scoring needs. Your privacy and trust are important to us.</p>
            </div>
        </div>
    )
}

export default Privacy
import React from "react";
import { Helmet } from "react-helmet";

const Head = ({title}) => {
    return (
        <Helmet>
            <title>{title || "Ace to King"}</title>
            <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
            />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Leckerli+One&family=Roboto:wght@300;400;500;700&display=swap" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        </Helmet>
    )
}

export default Head;
import React from "react";
import Head from "./Head";
import Header from "./components/Header";
import { Link } from "react-router-dom";

import './Home.css'

const Home = () => {
    return (
        <div className="Home">
            <Head />
            <Header />

            <div className="appStore">
                Coming soon to the Apple App Store!
            </div>

            <div className="links">
                <Link to="/webapp">Use the Web App</Link> | <Link to="/privacy">Privacy Policy</Link>
            </div>
        </div>
    )
}

export default Home;